
.template__wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}

.template__btn__link {
    margin-left: 18px;
    margin-top: 10px;
    display: inline-block;
}