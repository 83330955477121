.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-block {
    display: flex;
    align-items: center;
}

.sidebar__card__wrapper {
    margin-top: 20px;
}

.sidebar__card__content__right {
    display: flex;
    align-items: flex-end;
    margin-top: 16px;
}

.sidebar__content__statistic {
    margin-top: 25px;
}

.sidebar__card {
    height: 100%;
}

.sidebar__report .sidebar__card {
    margin-top: 18px;
    margin-left: 0 !important;
}

.sidebar__report .table__cell__offer {
    justify-content: normal;
}

.sidebar__card.type__alert {
    background-color: #FFF0F0;
}

.sidebar__card:nth-child(2) {
    margin-left: 20px;
}

.sidebar__content__item {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

#app {
    height: 100%;
}

.app {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.Theme {
    height: 100%;
}

.TextField {
    width: 100%;
}

.header {
    background: var(--white-color);
    z-index: 1001;
}

.header_container {
    width: 1200px;
    max-width: 1200px;
}

.header.mobile {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1001;
}

.header__dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding-bottom: 25px;
}

.header__default_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 75px;
}

.header__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 245px;
}

.header__menu__item {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.03em;
    color: var(--gray-color);
}

.header__menu__item:hover a {
    color: #757575;
}

.dark .header__menu__item {
    color: #7288A7;
}

.dark .header__menu__item:hover a {
    color: #7288A7;
}

.header__menu__item.header__menu__item_active,
.dark .header__menu__item.header__menu__item_active {
    color: #5699FF;
    font-weight: 800;
    position: relative;
}

.header__menu__item.header__menu__item_active:hover a {
    color: #5699FF;
}

.header__menu__item.header__menu__item_active::after {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #5699FF;
    position: absolute;
    bottom: -8px;
    right: 50%;
    left: 50%;
}

.header__auth_user__links {
    display: flex;
}

menu a:hover {
    text-decoration: none;
}

.header__logo__img {
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    width: 71px;
}


.header_burger {
    display: block;
    position: relative;
    height: 22px;
    width: 29px;
    z-index: 1000;
}

.header_burger.active {
    right: 9px !important;
    left: 88%;
}

.header_burger:hover {
    cursor: pointer;
}

.burger {
    position: absolute;
    width: 23px;
    height: 3px;
    right: 0;
    top: 8px;
    background-color: #5699FF;
    border-radius: 3px;
}

.header_burger:after,
.header_burger:before {
    position: absolute;
    content: "";
    border-radius: 3px;
    background-color: #5699FF;
    width: 29px;
    height: 3px;
    right: 0;
    transition: all ease 0.5s;
}

.header_burger:after {
    bottom: 3px;
}

.header_burger:before {
    top: 0;
}

.header_burger.active:after {
    transform: rotate(-45deg);
    bottom: 9px;
    transition: all ease 0.5s;
    opacity: 1;
    background-color: #fff;
}

.header_burger.active:before {
    transform: rotate(45deg);
    top: 10px;
    opacity: 1;
    background-color: #fff;
}

.header_burger:hover:after {
    opacity: 1;
}

.header_burger:hover:before {
    opacity: 1;
}

.header_burger:hover .burger {
    opacity: 1;
}

.header_burger.active span {
    transform: scale(0);
}

.close_btn_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    width: 37px;
}

.control__btn-login {
    margin-right: 10px;
    margin-left: 27px;
}

.footer {
    background-size: cover;
    height: 445px;
    width: 101%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    padding-bottom: 25px;
    z-index: 0;
    background-position: -2px 4px;
    background-repeat: no-repeat;
    bottom: 0;
    flex: 0 0 auto;
}

.footer__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 150px;
    margin-bottom: 35px;
}

.footer__menu__item {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */
    text-align: center;
    letter-spacing: -0.03em;
    /* Фон */
    color: var(--white-blue-color-text);
    transition: all 0.2s ease-in-out;
}

.footer__menu__item:hover {
    text-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
}

.footer__logo__img {
    width: 124px;
}

.footer__menu__item_light {
    font-weight: normal;
}

.footer__menu__item_active {
    font-weight: 800;
    color: var(--active-link-color) !important;
}

.footer__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer__socials__item svg path {
    transition: all .4s ease-in-out;
}

.dark .footer__socials__item svg path {
    fill: #30578E;
}

.footer__socials__item:hover svg path {
    fill: var(--white-color);
}

.dark .footer__socials__item:hover svg path {
    fill: #93C0FF;
}

.modal-active {
    pointer-events: none
}

.modal-active.active_modal-purpose {
    animation: blur-in-less 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.content__wrapper {
    flex: 1 0 auto;
    z-index: 1005;
}

.app__content {
    transition: filter .3s;
    position: relative;
    min-height: 200px;
    padding: 20px;
}

.input-body__wrapper {
    margin-top: 19px;
    width: 100%;
}

.login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login__form {
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-submit__btn {
    width: 80%;
    margin-top: 32px;
}

.logo__link {
    text-decoration: none;
    margin: 0 20px;
    display: inline-block;
}

.sidebar__content {
    padding: 30px 20px;
}