
* {
    font-family: 'Lato', sans-serif;
    padding: 0;
    margin: 0;
}

html, body {
    height: 100%;
}

body {
    overflow-x: hidden;
    background: var(--bg-color);
}

p, menu, h1, h2, h3, h4, h5, h6, label {
    margin: 0;
    padding: 0;
}

a {
    list-style: none;
}

button {
    cursor: pointer;
    background: none;
}

menu, nav {
    list-style: none;
}

input {
    border: none;
}

a {
    color: inherit;
}

a:hover {
    text-decoration: none;
}

.label-checkbox {
    user-select: none;
}

progress[value] {
    -webkit-appearance: none;
    appearance: none;
}

.select-wrapper {
    height: auto;
    font-family: Inter, sans-serif;
    position: absolute;
    background: var(--white-color);
    cursor: pointer;
    color: #5699FF;
}

.select_list {
    padding: 7px 0 0 0;
    list-style: none;
    width: 100%;
    margin-bottom: 0;
}

.select_item {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: #93A7C7;
    cursor: pointer;
    padding: 12px 0;
}

.inputs-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputs-row > input {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
}
.inputs-row.half:first-child {
    padding-right: 10px;
}
.inputs-row.half:nth-child(2n) {
    padding-left: 10px;
}

.input {
    -webkit-appearance: none;
    background: var(--input-bg-color);
    border-radius: 15px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.04em;
    padding-left: 20px;
    padding-right: 15px;
    height: 55px;
    width: 100%;
    margin: 7px 9px;
    z-index: inherit;
    color: var(--input-text-color);
}

.input:focus {
    color: var(--input-text-color);
}

.input::placeholder {
    font-style: normal;
    font-weight: 500;
    color: var(--input-placeholder-color);
}

.input-danger {
    background: var(--input-danger-bg-color);
    border: 1px solid #D14A41;
    box-sizing: border-box;
    border-radius: 15px;
    color: #D14A41;
}

.input-label {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: #822620;
    margin-top: -3px;
    margin-left: 9px;
    display: none;
}

.input-label_display {
    display: block;
}

.input-danger::placeholder {
    color: #D14A41;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

option {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.04em;
    color: #93A7C7;
}

@media screen and (max-width: 768px) {
    .sign__select_position {
        padding-left: 20px;
    }
}

@media screen and (max-width: 576px) {
    .input {
        font-size: 14px;
    }

    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="text"],
    select:focus,
    textarea {
        font-size: 16px;
    }
    .inputs-row.half {
        padding: 0;
    }
}